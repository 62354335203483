import { render, staticRenderFns } from "./image-editor.vue?vue&type=template&id=021ae986&scoped=true&"
import script from "./image-editor.vue?vue&type=script&lang=js&"
export * from "./image-editor.vue?vue&type=script&lang=js&"
import style0 from "./image-editor.vue?vue&type=style&index=0&id=021ae986&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "021ae986",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/jasilawil.uz/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('021ae986')) {
      api.createRecord('021ae986', component.options)
    } else {
      api.reload('021ae986', component.options)
    }
    module.hot.accept("./image-editor.vue?vue&type=template&id=021ae986&scoped=true&", function () {
      api.rerender('021ae986', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/modals/image-editor.vue"
export default component.exports