import { render, staticRenderFns } from "./register-type.vue?vue&type=template&id=618d2776&"
import script from "./register-type.vue?vue&type=script&lang=js&"
export * from "./register-type.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/jasilawil.uz/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('618d2776')) {
      api.createRecord('618d2776', component.options)
    } else {
      api.reload('618d2776', component.options)
    }
    module.hot.accept("./register-type.vue?vue&type=template&id=618d2776&", function () {
      api.rerender('618d2776', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/modals/register-type.vue"
export default component.exports