import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31bacd38 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4670f632 = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "pages/index/index" */))
const _85e0abbc = () => interopDefault(import('../pages/index/about.vue' /* webpackChunkName: "pages/index/about" */))
const _265f7734 = () => interopDefault(import('../pages/index/advisory.vue' /* webpackChunkName: "pages/index/advisory" */))
const _6d0a9a76 = () => interopDefault(import('../pages/index/agri-business.vue' /* webpackChunkName: "pages/index/agri-business" */))
const _24255188 = () => interopDefault(import('../pages/index/agri-business/index.vue' /* webpackChunkName: "pages/index/agri-business/index" */))
const _59e5ca28 = () => interopDefault(import('../pages/index/agri-finance/index.vue' /* webpackChunkName: "pages/index/agri-finance/index" */))
const _cc39befe = () => interopDefault(import('../pages/index/agri-market.vue' /* webpackChunkName: "pages/index/agri-market" */))
const _3f50fbaf = () => interopDefault(import('../pages/index/agro-tools/index.vue' /* webpackChunkName: "pages/index/agro-tools/index" */))
const _6a9d2b9a = () => interopDefault(import('../pages/index/chats/index.vue' /* webpackChunkName: "pages/index/chats/index" */))
const _6faa418a = () => interopDefault(import('../pages/index/chats-old.vue' /* webpackChunkName: "pages/index/chats-old" */))
const _008c90f0 = () => interopDefault(import('../pages/index/consultant-enterprise.vue' /* webpackChunkName: "pages/index/consultant-enterprise" */))
const _5dd46a80 = () => interopDefault(import('../pages/index/consultant-individual.vue' /* webpackChunkName: "pages/index/consultant-individual" */))
const _41403240 = () => interopDefault(import('../pages/index/content-provider.vue' /* webpackChunkName: "pages/index/content-provider" */))
const _3cd56c84 = () => interopDefault(import('../pages/index/e-learning/index.vue' /* webpackChunkName: "pages/index/e-learning/index" */))
const _7217013a = () => interopDefault(import('../pages/index/graph.vue' /* webpackChunkName: "pages/index/graph" */))
const _b321b004 = () => interopDefault(import('../pages/index/login.vue' /* webpackChunkName: "pages/index/login" */))
const _4845dea3 = () => interopDefault(import('../pages/index/marketplace/index.vue' /* webpackChunkName: "pages/index/marketplace/index" */))
const _c3b52c16 = () => interopDefault(import('../pages/index/new.vue' /* webpackChunkName: "pages/index/new" */))
const _2a3375ce = () => interopDefault(import('../pages/index/news.vue' /* webpackChunkName: "pages/index/news" */))
const _5617e9d1 = () => interopDefault(import('../pages/index/news/index.vue' /* webpackChunkName: "pages/index/news/index" */))
const _21bf19af = () => interopDefault(import('../pages/index/news/_detail.vue' /* webpackChunkName: "pages/index/news/_detail" */))
const _409fefc4 = () => interopDefault(import('../pages/index/register.vue' /* webpackChunkName: "pages/index/register" */))
const _5f78738e = () => interopDefault(import('../pages/index/register-type.vue' /* webpackChunkName: "pages/index/register-type" */))
const _4673bcd8 = () => interopDefault(import('../pages/index/reset-password.vue' /* webpackChunkName: "pages/index/reset-password" */))
const _7348c9f8 = () => interopDefault(import('../pages/index/user-enterprise.vue' /* webpackChunkName: "pages/index/user-enterprise" */))
const _44a4dd30 = () => interopDefault(import('../pages/index/user-individual.vue' /* webpackChunkName: "pages/index/user-individual" */))
const _1fb3c977 = () => interopDefault(import('../pages/index/e-learning/detail_old.vue' /* webpackChunkName: "pages/index/e-learning/detail_old" */))
const _69964046 = () => interopDefault(import('../pages/index/agri-finance/_detail.vue' /* webpackChunkName: "pages/index/agri-finance/_detail" */))
const _4f26bba2 = () => interopDefault(import('../pages/index/e-learning/_detail.vue' /* webpackChunkName: "pages/index/e-learning/_detail" */))
const _40432201 = () => interopDefault(import('../pages/index/marketplace/_detail.vue' /* webpackChunkName: "pages/index/marketplace/_detail" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _31bacd38,
    children: [{
      path: "",
      component: _4670f632,
      name: "index___en"
    }, {
      path: "about",
      component: _85e0abbc,
      name: "index-about___en"
    }, {
      path: "advisory",
      component: _265f7734,
      name: "index-advisory___en"
    }, {
      path: "agri-business",
      component: _6d0a9a76,
      children: [{
        path: "",
        component: _24255188,
        name: "index-agri-business___en"
      }]
    }, {
      path: "agri-finance",
      component: _59e5ca28,
      name: "index-agri-finance___en"
    }, {
      path: "agri-market",
      component: _cc39befe,
      name: "index-agri-market___en"
    }, {
      path: "agro-tools",
      component: _3f50fbaf,
      name: "index-agro-tools___en"
    }, {
      path: "chats",
      component: _6a9d2b9a,
      name: "index-chats___en"
    }, {
      path: "chats-old",
      component: _6faa418a,
      name: "index-chats-old___en"
    }, {
      path: "consultant-enterprise",
      component: _008c90f0,
      name: "index-consultant-enterprise___en"
    }, {
      path: "consultant-individual",
      component: _5dd46a80,
      name: "index-consultant-individual___en"
    }, {
      path: "content-provider",
      component: _41403240,
      name: "index-content-provider___en"
    }, {
      path: "e-learning",
      component: _3cd56c84,
      name: "index-e-learning___en"
    }, {
      path: "graph",
      component: _7217013a,
      name: "index-graph___en"
    }, {
      path: "login",
      component: _b321b004,
      name: "index-login___en"
    }, {
      path: "marketplace",
      component: _4845dea3,
      name: "index-marketplace___en"
    }, {
      path: "new",
      component: _c3b52c16,
      name: "index-new___en"
    }, {
      path: "news",
      component: _2a3375ce,
      children: [{
        path: "",
        component: _5617e9d1,
        name: "index-news___en"
      }, {
        path: ":detail",
        component: _21bf19af,
        name: "index-news-detail___en"
      }]
    }, {
      path: "register",
      component: _409fefc4,
      name: "index-register___en"
    }, {
      path: "register-type",
      component: _5f78738e,
      name: "index-register-type___en"
    }, {
      path: "reset-password",
      component: _4673bcd8,
      name: "index-reset-password___en"
    }, {
      path: "user-enterprise",
      component: _7348c9f8,
      name: "index-user-enterprise___en"
    }, {
      path: "user-individual",
      component: _44a4dd30,
      name: "index-user-individual___en"
    }, {
      path: "e-learning/detail_old",
      component: _1fb3c977,
      name: "index-e-learning-detail_old___en"
    }, {
      path: "agri-finance/:detail?",
      component: _69964046,
      name: "index-agri-finance-detail___en"
    }, {
      path: "e-learning/:detail?",
      component: _4f26bba2,
      name: "index-e-learning-detail___en"
    }, {
      path: "marketplace/:detail",
      component: _40432201,
      name: "index-marketplace-detail___en"
    }]
  }, {
    path: "/kaa",
    component: _31bacd38,
    children: [{
      path: "",
      component: _4670f632,
      name: "index___kaa"
    }, {
      path: "about",
      component: _85e0abbc,
      name: "index-about___kaa"
    }, {
      path: "advisory",
      component: _265f7734,
      name: "index-advisory___kaa"
    }, {
      path: "agri-business",
      component: _6d0a9a76,
      children: [{
        path: "",
        component: _24255188,
        name: "index-agri-business___kaa"
      }]
    }, {
      path: "agri-finance",
      component: _59e5ca28,
      name: "index-agri-finance___kaa"
    }, {
      path: "agri-market",
      component: _cc39befe,
      name: "index-agri-market___kaa"
    }, {
      path: "agro-tools",
      component: _3f50fbaf,
      name: "index-agro-tools___kaa"
    }, {
      path: "chats",
      component: _6a9d2b9a,
      name: "index-chats___kaa"
    }, {
      path: "chats-old",
      component: _6faa418a,
      name: "index-chats-old___kaa"
    }, {
      path: "consultant-enterprise",
      component: _008c90f0,
      name: "index-consultant-enterprise___kaa"
    }, {
      path: "consultant-individual",
      component: _5dd46a80,
      name: "index-consultant-individual___kaa"
    }, {
      path: "content-provider",
      component: _41403240,
      name: "index-content-provider___kaa"
    }, {
      path: "e-learning",
      component: _3cd56c84,
      name: "index-e-learning___kaa"
    }, {
      path: "graph",
      component: _7217013a,
      name: "index-graph___kaa"
    }, {
      path: "login",
      component: _b321b004,
      name: "index-login___kaa"
    }, {
      path: "marketplace",
      component: _4845dea3,
      name: "index-marketplace___kaa"
    }, {
      path: "new",
      component: _c3b52c16,
      name: "index-new___kaa"
    }, {
      path: "news",
      component: _2a3375ce,
      children: [{
        path: "",
        component: _5617e9d1,
        name: "index-news___kaa"
      }, {
        path: ":detail",
        component: _21bf19af,
        name: "index-news-detail___kaa"
      }]
    }, {
      path: "register",
      component: _409fefc4,
      name: "index-register___kaa"
    }, {
      path: "register-type",
      component: _5f78738e,
      name: "index-register-type___kaa"
    }, {
      path: "reset-password",
      component: _4673bcd8,
      name: "index-reset-password___kaa"
    }, {
      path: "user-enterprise",
      component: _7348c9f8,
      name: "index-user-enterprise___kaa"
    }, {
      path: "user-individual",
      component: _44a4dd30,
      name: "index-user-individual___kaa"
    }, {
      path: "e-learning/detail_old",
      component: _1fb3c977,
      name: "index-e-learning-detail_old___kaa"
    }, {
      path: "agri-finance/:detail?",
      component: _69964046,
      name: "index-agri-finance-detail___kaa"
    }, {
      path: "e-learning/:detail?",
      component: _4f26bba2,
      name: "index-e-learning-detail___kaa"
    }, {
      path: "marketplace/:detail",
      component: _40432201,
      name: "index-marketplace-detail___kaa"
    }]
  }, {
    path: "/ru",
    component: _31bacd38,
    children: [{
      path: "",
      component: _4670f632,
      name: "index___ru"
    }, {
      path: "about",
      component: _85e0abbc,
      name: "index-about___ru"
    }, {
      path: "advisory",
      component: _265f7734,
      name: "index-advisory___ru"
    }, {
      path: "agri-business",
      component: _6d0a9a76,
      children: [{
        path: "",
        component: _24255188,
        name: "index-agri-business___ru"
      }]
    }, {
      path: "agri-finance",
      component: _59e5ca28,
      name: "index-agri-finance___ru"
    }, {
      path: "agri-market",
      component: _cc39befe,
      name: "index-agri-market___ru"
    }, {
      path: "agro-tools",
      component: _3f50fbaf,
      name: "index-agro-tools___ru"
    }, {
      path: "chats",
      component: _6a9d2b9a,
      name: "index-chats___ru"
    }, {
      path: "chats-old",
      component: _6faa418a,
      name: "index-chats-old___ru"
    }, {
      path: "consultant-enterprise",
      component: _008c90f0,
      name: "index-consultant-enterprise___ru"
    }, {
      path: "consultant-individual",
      component: _5dd46a80,
      name: "index-consultant-individual___ru"
    }, {
      path: "content-provider",
      component: _41403240,
      name: "index-content-provider___ru"
    }, {
      path: "e-learning",
      component: _3cd56c84,
      name: "index-e-learning___ru"
    }, {
      path: "graph",
      component: _7217013a,
      name: "index-graph___ru"
    }, {
      path: "login",
      component: _b321b004,
      name: "index-login___ru"
    }, {
      path: "marketplace",
      component: _4845dea3,
      name: "index-marketplace___ru"
    }, {
      path: "new",
      component: _c3b52c16,
      name: "index-new___ru"
    }, {
      path: "news",
      component: _2a3375ce,
      children: [{
        path: "",
        component: _5617e9d1,
        name: "index-news___ru"
      }, {
        path: ":detail",
        component: _21bf19af,
        name: "index-news-detail___ru"
      }]
    }, {
      path: "register",
      component: _409fefc4,
      name: "index-register___ru"
    }, {
      path: "register-type",
      component: _5f78738e,
      name: "index-register-type___ru"
    }, {
      path: "reset-password",
      component: _4673bcd8,
      name: "index-reset-password___ru"
    }, {
      path: "user-enterprise",
      component: _7348c9f8,
      name: "index-user-enterprise___ru"
    }, {
      path: "user-individual",
      component: _44a4dd30,
      name: "index-user-individual___ru"
    }, {
      path: "e-learning/detail_old",
      component: _1fb3c977,
      name: "index-e-learning-detail_old___ru"
    }, {
      path: "agri-finance/:detail?",
      component: _69964046,
      name: "index-agri-finance-detail___ru"
    }, {
      path: "e-learning/:detail?",
      component: _4f26bba2,
      name: "index-e-learning-detail___ru"
    }, {
      path: "marketplace/:detail",
      component: _40432201,
      name: "index-marketplace-detail___ru"
    }]
  }, {
    path: "/uz",
    component: _31bacd38,
    children: [{
      path: "",
      component: _4670f632,
      name: "index___uz"
    }, {
      path: "about",
      component: _85e0abbc,
      name: "index-about___uz"
    }, {
      path: "advisory",
      component: _265f7734,
      name: "index-advisory___uz"
    }, {
      path: "agri-business",
      component: _6d0a9a76,
      children: [{
        path: "",
        component: _24255188,
        name: "index-agri-business___uz"
      }]
    }, {
      path: "agri-finance",
      component: _59e5ca28,
      name: "index-agri-finance___uz"
    }, {
      path: "agri-market",
      component: _cc39befe,
      name: "index-agri-market___uz"
    }, {
      path: "agro-tools",
      component: _3f50fbaf,
      name: "index-agro-tools___uz"
    }, {
      path: "chats",
      component: _6a9d2b9a,
      name: "index-chats___uz"
    }, {
      path: "chats-old",
      component: _6faa418a,
      name: "index-chats-old___uz"
    }, {
      path: "consultant-enterprise",
      component: _008c90f0,
      name: "index-consultant-enterprise___uz"
    }, {
      path: "consultant-individual",
      component: _5dd46a80,
      name: "index-consultant-individual___uz"
    }, {
      path: "content-provider",
      component: _41403240,
      name: "index-content-provider___uz"
    }, {
      path: "e-learning",
      component: _3cd56c84,
      name: "index-e-learning___uz"
    }, {
      path: "graph",
      component: _7217013a,
      name: "index-graph___uz"
    }, {
      path: "login",
      component: _b321b004,
      name: "index-login___uz"
    }, {
      path: "marketplace",
      component: _4845dea3,
      name: "index-marketplace___uz"
    }, {
      path: "new",
      component: _c3b52c16,
      name: "index-new___uz"
    }, {
      path: "news",
      component: _2a3375ce,
      children: [{
        path: "",
        component: _5617e9d1,
        name: "index-news___uz"
      }, {
        path: ":detail",
        component: _21bf19af,
        name: "index-news-detail___uz"
      }]
    }, {
      path: "register",
      component: _409fefc4,
      name: "index-register___uz"
    }, {
      path: "register-type",
      component: _5f78738e,
      name: "index-register-type___uz"
    }, {
      path: "reset-password",
      component: _4673bcd8,
      name: "index-reset-password___uz"
    }, {
      path: "user-enterprise",
      component: _7348c9f8,
      name: "index-user-enterprise___uz"
    }, {
      path: "user-individual",
      component: _44a4dd30,
      name: "index-user-individual___uz"
    }, {
      path: "e-learning/detail_old",
      component: _1fb3c977,
      name: "index-e-learning-detail_old___uz"
    }, {
      path: "agri-finance/:detail?",
      component: _69964046,
      name: "index-agri-finance-detail___uz"
    }, {
      path: "e-learning/:detail?",
      component: _4f26bba2,
      name: "index-e-learning-detail___uz"
    }, {
      path: "marketplace/:detail",
      component: _40432201,
      name: "index-marketplace-detail___uz"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
