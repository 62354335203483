import { render, staticRenderFns } from "./Error.vue?vue&type=template&id=cabd6730&"
import script from "./Error.vue?vue&type=script&lang=js&"
export * from "./Error.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/jasilawil.uz/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cabd6730')) {
      api.createRecord('cabd6730', component.options)
    } else {
      api.reload('cabd6730', component.options)
    }
    module.hot.accept("./Error.vue?vue&type=template&id=cabd6730&", function () {
      api.rerender('cabd6730', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/ui/Error.vue"
export default component.exports